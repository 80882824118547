import tableFields from "./newsCategoryListTableFields.json";
import ModalContent from "./ModalContent";
import { toast } from "react-toastify";
import ListPage from "../../components/shared/ListPage/ListPage";
import useAPI from "../../api/api";

const NewsCategoryList = () => {
    const api = useAPI();

    const buttons = [
        {
            id: 1,
            label: "Vesti",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return `/news`;
                },
            },
        },
    ];

    const customActions = {
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData, handleDeleteModalData) => {
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                        children: <ModalContent apiPath={`admin/news/category/list/message/${rowData.id}`} rowData={rowData} handleDeleteModalData={handleDeleteModalData} />,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData, deleteModalData) => {
                    api.delete(`admin/news/category/list/confirm/${rowData.id}`, {}, "CATEGORY_NEWS_DELETE")
                        .then(() => toast.success("Zapis je uspešno obrisan"))
                        .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške prilikom brisanja"));

                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    return (
        <ListPage
            listPageId="NewsCategorylist"
            apiUrl="admin/news/category/list"
            title="Vesti kategorije"
            columnFields={tableFields}
            additionalButtons={buttons}
            customActions={customActions}
            screen_code="CATEGORY_NEWS_LIST"
        />
    );
};

export default NewsCategoryList;
