import React, { useState } from "react";
import ListPageModalWrapper from "../Modal/ListPageModalWrapper";
import { Box, Typography } from "@mui/material";
import Form from "../Form/Form";
import useAPI from "../../../api/api";
import { toast } from "react-toastify";

const CustomFormDialog = ({ title, isOpen, onClose, onSave, formFields, queryString, initialData, addFilterApiUrl, screen_code }) => {
    const api = useAPI();

    const [formData, setFormData] = useState(initialData);

    const handleChange = (data) => {
        setFormData(data);
    };
    const handleSubmit = (data) => {
        api.post(addFilterApiUrl, data, `NOTIFICATIONS_UPDATE`)
            .then(() => {
                toast.success(`Uspešno ste dodali filter.`);
                onSave();
                onClose();
            })
            .catch((error) => {
                toast.warning(error.response.data.message ?? error?.response?.data?.payload?.message ?? "Greška pri dodavanju filtera.");
            });
    };

    return (
        <ListPageModalWrapper anchor={`right`} open={isOpen} onClose={onClose} onCloseButtonClick={onClose}>
            <Box sx={{ padding: 2 }}>
                <Typography variant={`h6`} mb={2}>
                    {title}
                </Typography>
                <Form formFields={formFields} initialData={formData} queryString={queryString} onSubmit={handleSubmit} onChange={handleChange} screen_code={screen_code} />
            </Box>
        </ListPageModalWrapper>
    );
};

export default CustomFormDialog;
