import ListPage from "../../components/shared/ListPage/ListPage";
import fields from "./tblFields.json";
import { toast } from "react-toastify";
import { useState } from "react";
import DeleteDialog from "../../components/shared/Dialogs/DeleteDialog";
import useAPI from "../../api/api";
import { useNavigate } from "react-router-dom";
export const AllRegistrations = () => {
    const [doesRefetch, setDoesRefetch] = useState(false);
    const buttons = [
        {
            label: "Nova",
            title: "Nova",
            variant: "outlined",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return `/registrations/new`;
                },
            },
        },
        {
            label: "Prihvaćene",
            title: "Prihvaćene",
            variant: "outlined",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return `/registrations/accepted`;
                },
            },
        },
        {
            label: "Odbijene",
            title: "Odbijene",
            variant: "outlined",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return `/registrations/rejected`;
                },
            },
        },
        {
            label: "Nedovršene",
            title: "Nedovršene",
            variant: "outlined",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return `/registrations/incomplete`;
                },
            },
        },
    ];

    const api = useAPI();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        id: "",
        data: [],
        main_line: "",
    });

    const customActions = {
        edit: {
            icon: "edit",
            position: 1,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    navigate(`/registrations/main/${rowData?.id}/${rowData?.firma_id}`);
                },
            },
        },
        delete: {
            position: 2,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    api.get(`admin/registration/main/message/${rowData?.id}`, "REGISTRATIONS_MAIN_LIST")
                        .then((res) => {
                            setDeleteModal({
                                show: true,
                                id: rowData.id,
                                ...res?.payload,
                            });
                        })
                        .catch((err) => toast.warning(err?.response?.data?.message ?? "Došlo je do greške"));
                },
            },
        },
    };

    const handleDeleteAfterMesage = () => {
        api.delete(`admin/registration/main/confirm/${deleteModal?.id}`, {}, "REGISTRATIONS_MAIN_DELETE")
            .then((res) => {
                toast.success("Uspešno obrisano");
                setDeleteModal({
                    show: false,
                    id: "",
                    data: [],
                    main_line: "",
                });
                setDoesRefetch(!doesRefetch);
            })
            .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške"));
    };

    return (
        <>
            <ListPage
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                showNewButton={false}
                customActions={customActions}
                title={`Nove registracije`}
                columnFields={fields}
                apiUrl={`admin/registration/main/list`}
                screen_code={"REGISTRATIONS_MAIN_LIST"}
                additionalButtons={buttons}
            />
            <DeleteDialog
                title={deleteModal?.portal_customer_line ?? "Brisanje"}
                screen_code={"REGISTRATIONS_MAIN_DELETE"}
                handleConfirm={handleDeleteAfterMesage}
                description={deleteModal?.main_line ?? ""}
                openDeleteDialog={deleteModal}
                setOpenDeleteDialog={setDeleteModal}
                nameOfButton={deleteModal?.portal_customer_checkbox ?? "Obriši"}
            />
        </>
    );
};
