import ListPage from "../../components/shared/ListPage/ListPage";
import tblFields from "./tblFields.json";
import useAPI from "../../api/api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import DeleteModal from "../../components/shared/Dialogs/DeleteDialog";
import ChangePasswordDialog from "../../components/shared/ChangePasswordDialog/ChangePasswordDialog";
import { useMutation, useQuery } from "react-query";
import Button from "../../components/shared/Button/Button";
import IconList from "../../helpers/icons";
import { InputCheckbox } from "../../components/shared/Form/FormInputs/FormInputs";

const Accounts = () => {
    const [params] = useSearchParams();
    const showConfirm = params.get("confirm");

    const [doesRefetch, setDoesRefetch] = useState(false);
    const [openModalUncheckedSet, setOpenModalUncheckedSet] = useState({ show: false, id: null });
    const api = useAPI();
    const [tableFields, setTableFields] = useState(tblFields);
    const { contractId, customerId } = useParams();
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState({
        show: false,
        id: null,
        name: null,
        data: [],
    });

    const additionalButtons = [
        {
            label: "Novi unos",
            title: "Novi unos",
            variant: "contained",
            icon: "add",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    if (showConfirm === "true") {
                        return `/accounts/${customerId}/${contractId}/new?confirm=true`;
                    } else {
                        return `/accounts/${customerId}/${contractId}/new`;
                    }
                },
            },
        },
    ];

    const customActions = {
        key: {
            type: "custom",
            display: true,
            position: 3,
            clickHandler: {
                type: "",
                fnc: function (rowData) {
                    return setOpenDialog({ show: true, userId: rowData.id });
                },
            },
            icon: "key",
            title: "Promeni lozinku",
        },
        account: {
            type: "custom",
            display: true,
            position: 4,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    return api
                        .post(
                            `admin/contracts/accounts/list/impersonate/hash`,
                            {
                                id: rowData?.id,
                            },
                            "PORTAL_CONTRACTS_CONFIRM_POST"
                        )
                        .then((res) => {
                            const hash = res?.payload?.hash;
                            //redirect in new tab
                            window.open(`https://portalscoring.croonus.com/login?hash=${hash}`);
                        })
                        ?.catch((err) => err);
                },
            },
            icon: "group",
            title: "Uloguj se kao korisnik",
        },
        edit: {
            icon: "edit",
            position: 1,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    if (showConfirm === "true") {
                        return `/accounts/${customerId}/${contractId}/${rowData?.id}?confirm=true`;
                    } else {
                        return `/accounts/${customerId}/${contractId}/${rowData?.id}`;
                    }
                },
            },
        },
        delete: {
            position: 2,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    setOpenModalUncheckedSet({ show: true, id: rowData.id });
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    api.delete(`admin/contracts/accounts/list/${rowData.id}`, {}, "ACCOUNTS_DELETE")
                        .then((res) => toast.success(res?.payload?.message ?? "Zapis je uspešno obrisan"))
                        .catch(() => toast.warning("Došlo je do greške prilikom brisanja"));
                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    const deleteHandler = async (id) => {
        api.delete(`admin/contracts/accounts/list/${id}`, {}, "ACCOUNTS_DELETE")
            .then((response) => {
                setDoesRefetch(true);
                toast.success("Uspešno");
            })
            .catch((error) => {
                toast.warn(error?.response?.data?.message ?? "Greška");
                console.warn(error);
            });
    };
    const [openDialog, setOpenDialog] = useState({ show: false, userId: null });

    const { data, isLoading } = useQuery({
        queryKey: ["accounts", { contractId }],
        queryFn: async () => {
            return await api.get(`/admin/contracts/accounts/basic-data/data/${contractId}`, "ACCOUNTS_DETAILS").then((res) => res?.payload);
        },
    });

    const [sendEmail, setSendEmail] = useState(true);

    const { mutate: confirm } = useMutation(
        ["confirmContract"],
        async () => {
            return await api
                .post(
                    `admin/contracts/basic-data/confirm/${contractId}`,
                    {
                        send_email: sendEmail,
                    },
                    "PORTAL_CONTRACTS_CONFIRM_POST"
                )
                .then((res) => {
                    toast.success("Uspešno potvrđeno");
                    navigate(-1);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message ?? "Greška");
                });
        },
        {}
    );

    const { data: isEnabled, isLoading: isLoadingPermission } = useQuery(
        ["isAllowedToAddAccounts", contractId],
        async () => {
            return await api.get(`admin/contracts/accounts/basic-data/consultant/permissions/${contractId}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        {}
    );

    return (
        <>
            {showConfirm === "true" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "10px",
                        margin: "0 0 2rem 2rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            navigate(`/portal-customers/contracts/${customerId}/${contractId}?tab=comments&confirm=true`, { replace: true });
                        }}
                        icon={IconList.arrowBack}
                        text="Nazad"
                        label={`Prethodno`}
                    />
                </div>
            )}
            {!isLoadingPermission && (
                <ListPage
                    listPageId="Accounts"
                    apiUrl={`admin/contracts/accounts/list/list/${contractId}`}
                    client_id={customerId}
                    additionalButtons={isEnabled?.enabled ? additionalButtons : []}
                    customActions={customActions}
                    title={`Nalozi`}
                    columnFields={tableFields}
                    useColumnFields={false}
                    formFields={tableFields}
                    showNewButton={false}
                    screen_code={"ACCOUNTS_LIST"}
                    doesRefetch={doesRefetch}
                    setDoesRefetch={setDoesRefetch}
                />
            )}
            {showConfirm === "true" && (
                <div
                    style={{
                        margin: "1rem 0 1rem 2rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Button
                        onClick={() => {
                            confirm();
                        }}
                        label={`Potvrdi ugovor`}
                        variant={`contained`}
                        sx={{ width: "fit-content" }}
                    />
                    <InputCheckbox
                        fullWidth={false}
                        value={sendEmail}
                        label={`Pošalji potvrdu ugovora klijentu na email`}
                        onChange={({ target: { checked } }) => {
                            setSendEmail(checked);
                        }}
                    />
                </div>
            )}
            <DeleteModal
                openDeleteDialog={openModalUncheckedSet}
                setOpenDeleteDialog={setOpenModalUncheckedSet}
                description={openModal?.data?.main_line}
                screen_code={"ACCOUNTS_DELETE"}
                nameOfButton={openModal?.data?.main_checkbox}
                handleConfirm={() => {
                    setOpenModalUncheckedSet({ show: false });
                    deleteHandler(openModalUncheckedSet?.id);
                }}
            />
            <ChangePasswordDialog screen_code={"ACCOUNTS_UPDATE"} openDialog={openDialog} setOpenDialog={setOpenDialog} apiPath="admin/contracts/accounts/basic-data/reset-password" />
        </>
    );
};

export default Accounts;
