import React, { useEffect, useState } from "react";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import tableFields from "../forms/accountTableColumns.json";

const Account = ({ notificationId, isSent }) => {
    const [shownTableFields, setShownTableFields] = useState(tableFields);

    const customActions = {
        edit: {
            type: "edit",
            display: false,
        },
    };

    const newSentNotificationStatusColumns = [
        {
            field_name: "Vreme dostave obaveštenja",
            prop_name: "delivery_time",
            in_main_table: true,
            in_details: false,
            editable: true,
            disabled: false,
            required: true,
            description: "",
            ui_prop: "xyz",
            sortable: true,
            input_type: "date_time",
        },
        {
            field_name: "Vreme prvog pregleda obaveštenja",
            prop_name: "first_viewed_time",
            in_main_table: true,
            in_details: false,
            editable: true,
            disabled: false,
            required: true,
            description: "",
            ui_prop: "xyz",
            sortable: true,
            input_type: "date_time",
        },
        {
            field_name: "Vreme poslednjeg pregleda obaveštenja",
            prop_name: "last_viewed_time",
            in_main_table: true,
            in_details: false,
            editable: true,
            disabled: false,
            required: true,
            description: "",
            ui_prop: "xyz",
            sortable: true,
            input_type: "date_time",
        },
        {
            field_name: "Status",
            prop_name: "status",
            in_main_table: true,
            in_details: false,
            editable: true,
            disabled: false,
            required: true,
            description: "",
            ui_prop: "xyz",
            sortable: true,
            input_type: "date_time",
        },
    ];

    useEffect(() => {
        if (isSent) {
            setShownTableFields((prev) => [...prev, ...newSentNotificationStatusColumns]);
        }
    }, [isSent]);

    return (
        <ListPage
            listPageId="Account"
            apiUrl={`admin/notifications/accounts/list/list/${notificationId}`}
            deleteUrl={`admin/notifications/accounts/list`}
            editUrl={`/admin/notifications/accounts/basic-data`}
            initialData={{ id_notifications: notificationId }}
            title="Nalozi"
            columnFields={shownTableFields}
            showNewButton={!isSent}
            actionNewButton="modal"
            customActions={customActions}
            screen_code="NOTIFICATIONS_DETAILS"
        />
    );
};

export default Account;
