import React, { useEffect, useState } from "react";
import useAPI from "../../../../api/api";
import Form from "../../../../components/shared/Form/Form";
import formFields from "../forms/statusFields.json";

const Status = ({ notificationId }) => {
    const api = useAPI();
    const [fields, setFields] = useState([]);

    useEffect(() => {
        api.get(`admin/notifications/status/${notificationId}`, "NOTIFICATIONS_DETAILS").then((res) => setFields(res?.payload));
    }, []);

    return <Form formFields={formFields} initialData={fields} submitButton={false} />;
};

export default Status;
