import React, { useState } from "react";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import tableFields from "../forms/overviewTableColumns.json";
import useAPI from "../../../../api/api";
import { toast } from "react-toastify";
import CustomActionsDialog from "../../../../components/shared/Dialogs/CustomActionsDialog";

const Overview = ({ onSubmit, notificationId }) => {
    const api = useAPI();
    const [shownTableFields, setShownTableFields] = useState(tableFields);
    const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);

    const handleOpenSendNotificationModal = () => setIsSendNotificationModalOpen(true);
    const handleCloseSendNotificationModal = () => setIsSendNotificationModalOpen(false);

    const customActions = {
        edit: {
            display: false,
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    return {
                        show: true,
                        id: rowData.id,
                        mutate: null,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    api.delete(
                        `/admin/notifications/overview/list`,
                        {
                            id: rowData.id,
                            channel: rowData.channel,
                        },
                        "NOTIFICATIONS_DELETE"
                    )
                        .then((response) => toast.success(response?.payload?.message || "Kontakt je uspešno obrisan sa liste."))
                        .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške prilikom brisanja"));

                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    const additionalButtons = [
        {
            label: "Pošalji obaveštenje",
            variant: "contained",
            clickHandler: {
                type: "",
                fnc: handleOpenSendNotificationModal,
            },
        },
    ];

    const handleConfirmSendingNotification = () =>
        api
            .post(
                `admin/notifications/overview/list/send`,
                {
                    id_notifications: notificationId,
                },
                "NOTIFICATIONS_UPDATE"
            )
            .then((res) => {
                onSubmit();
                toast.success(res?.payload?.message);
            });

    return (
        <>
            <CustomActionsDialog
                isOpen={isSendNotificationModalOpen}
                onConfirm={handleConfirmSendingNotification}
                onClose={handleCloseSendNotificationModal}
                title={`Da li ste sigurni da želite da pošaljete obaveštenje?`}
            />
            <ListPage
                listPageId="Overview"
                apiUrl={`/admin/notifications/overview/list/list/${notificationId}`}
                deleteUrl={`/admin/notifications/overview/list`}
                title="Pregled"
                columnFields={shownTableFields}
                customActions={customActions}
                additionalButtons={additionalButtons}
                showNewButton={false}
                screen_code="NOTIFICATIONS_DETAILS"
            />
        </>
    );
};

export default Overview;
