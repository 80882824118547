export const decodeQueryString = (text) => {
    let queryStringArray = {};
    if (typeof text === "string") {
        text = text ? text : "";
        let queryStringRaw = text ? text.split("&") : [];
        queryStringRaw.map((item) => {
            let itemArr = item.split("=");
            queryStringArray[itemArr[0]] = itemArr[1];
        });
    } else {
        queryStringArray = text;
    }
    return queryStringArray;
};

export const encodeQueryString = (array) => {
    let queryString = "";
    Object.keys(array).map((item) => {
        queryString += `${item}=${array[item]}&`;
    });

    queryString = queryString.slice(0, -1);

    return queryString;
};
