import ListPage from "../../components/shared/ListPage/ListPage";
import fields from "./tblFields.json";
import { useNavigate } from "react-router-dom";
import useAPI from "../../api/api";
import { useState } from "react";
import { toast } from "react-toastify";
import DeleteDialog from "../../components/shared/Dialogs/DeleteDialog";

export const AcceptedRegistrations = () => {
    const navigate = useNavigate();
    const [doesRefetch, setDoesRefetch] = useState(false);
    const buttons = [
        {
            label: "Nazad",
            title: "Nazad",
            icon: "arrow_back",
            variant: "outlined",
            clickHandler: {
                type: "navigate",
                fnc: () => {
                    return navigate(-1);
                },
            },
        },
    ];
    const api = useAPI();
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        id: "",
        data: [],
        main_line: "",
    });

    const customActions = {
        edit: {
            icon: "edit",
            position: 1,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    navigate(`/registrations/accepted/${rowData?.id}/${rowData?.firma_id}`);
                },
            },
        },
        delete: {
            position: 2,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    api.get(`admin/registration/accepted/message/${rowData?.id}`, "REGISTRATIONS_MAIN_LIST")
                        .then((res) => {
                            setDeleteModal({
                                show: true,
                                id: rowData.id,
                                ...res?.payload,
                            });
                        })
                        .catch((err) => toast.warning(err?.response?.data?.message ?? "Došlo je do greške"));
                },
            },
        },
    };

    const handleDeleteAfterMesage = () => {
        api.delete(`admin/registration/accepted/confirm/${deleteModal?.id}`, {}, "REGISTRATIONS_MAIN_DELETE")
            .then((res) => {
                toast.success("Uspešno obrisano");
                setDeleteModal({
                    show: false,
                    id: "",
                    data: [],
                    main_line: "",
                });
                setDoesRefetch(!doesRefetch);
            })
            .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške"));
    };

    return (
        <>
            <ListPage
                additionalButtons={buttons}
                customActions={customActions}
                showNewButton={false}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                title={`Prihvaćene registracije`}
                columnFields={fields}
                apiUrl={`admin/registration/accepted/list`}
                screen_code={"REGISTRATIONS_MAIN_LIST"}
            />
            <DeleteDialog
                title={deleteModal?.portal_customer_line ?? "Brisanje"}
                screen_code={"REGISTRATIONS_MAIN_DELETE"}
                handleConfirm={handleDeleteAfterMesage}
                description={deleteModal?.main_line ?? ""}
                openDeleteDialog={deleteModal}
                setOpenDeleteDialog={setDeleteModal}
                nameOfButton={deleteModal?.portal_customer_checkbox ?? "Obriši"}
            />
        </>
    );
};
