import { Chip, TextField, Box } from "@mui/material";
import React from "react";

const ChipsField = ({ data, onDelete }) => {
    return (
        <TextField
            label={data.name}
            variant="outlined"
            fullWidth
            multiline
            InputProps={{
                readOnly: true,
                sx: {
                    display: "flex",
                    alignItems: "center",
                    minHeight: "auto",
                    "& .MuiInputBase-input": { display: "none" },
                    py: 1,
                },
                startAdornment: (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, flexGrow: 1 }}>
                        {data.activeFilters.map(({ id, value }) => (
                            <Chip key={id} label={value} onDelete={() => onDelete(id, data.key)} sx={{ margin: 0.5 }} />
                        ))}
                    </Box>
                ),
            }}
        />
    );
};

export default ChipsField;
