import { Button, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

const FilterActionsList = ({ filters, onOpenDialog }) => {
    return (
        <Grid container justifyContent={`space-between`} my={2}>
            <Grid item>
                <Typography sx={{ fontSize: 20 }}>Filteri:</Typography>
            </Grid>
            <Grid
                item
                sx={{
                    display: `flex`,
                    gap: 1,
                }}
            >
                {filters?.map((filter, index) => (
                    <Fragment key={index}>
                        <Button variant={`outlined`} onClick={() => onOpenDialog(filter.dialog.key)}>
                            {filter.name}
                        </Button>
                        {filter.dialog && filter.dialog}
                    </Fragment>
                ))}
            </Grid>
        </Grid>
    );
};

export default FilterActionsList;
