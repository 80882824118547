import React from "react";
import Form from "../../../../components/shared/Form/Form";

const Content = ({ isSent, refetch, formFields, initialData, onSubmit, isLoading }) => {
    return (
        <Form
            refetch={refetch}
            formFields={!isSent ? formFields : formFields.map((field) => ({ ...field, disabled: true }))}
            initialData={initialData}
            submitButton={!isSent}
            onSubmit={onSubmit}
            loading={isLoading}
            apiPathCrop={`/admin/notifications/basic-data`}
            screen_code={`NOTIFICATIONS_DETAILS`}
        />
    );
};

export default Content;
