import React, { useEffect, useState } from "react";
import IconList from "../../../helpers/icons";
import Form from "../../../components/shared/Form/Form";
import DetailsPage from "../../../components/shared/ListPage/DetailsPage/DetailsPage";
import { useNavigate, useParams } from "react-router-dom";
import useAPI from "../../../api/api";
import content from "./forms/content.json";
import { getUrlQueryStringParam, setUrlQueryStringParam } from "../../../helpers/functions";
import { toast } from "react-toastify";
import PortalCustomers from "./panels/PortalCustomers";
import Account from "./panels/Account";
import Email from "./panels/Email";
import Overview from "./panels/Overview";
import Status from "./panels/Status";
import Content from "./panels/Content";

const NotificationsDetails = () => {
    const { notificationId } = useParams();
    const api = useAPI();
    const apiPath = `/admin/notifications/basic-data`;
    const navigate = useNavigate();
    const activeTab = getUrlQueryStringParam("tab") ?? "content";
    const [formFieldsTemp, setFormFieldsTemp] = useState(content);

    const init = {
        id: null,
        title: null,
        content: null,
        channels: null,
    };

    const [data, setData] = useState(init);
    const [accessibleFields, setAccessibleFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);

    const handleGetData = async () => {
        setIsLoading(true);
        api.get(`${apiPath}/${notificationId}`, `NOTIFICATIONS_DETAILS`)
            .then((response) => {
                setData(response?.payload);
                setIsLoading(false);
            })
            .catch((error) => {
                console.warn("Greska:", error);
                setIsLoading(false);
            });
    };

    const handleGetFields = () =>
        api
            .get(`/admin/notifications/menu-items/${notificationId}`, `NOTIFICATIONS_DETAILS`)
            .then((response) => setAccessibleFields(response?.payload ?? []))
            .catch((err) => console.warn("Greska", err));

    const handleSaveData = async (data) => {
        setIsLoadingOnSubmit(true);
        api.post(apiPath, data, `NOTIFICATIONS_NEW`)
            .then((response) => {
                setData(response?.payload);
                toast.success("Uspešno");

                if (data.id === null) {
                    const queryString = setUrlQueryStringParam("tab", "portal_customers");
                    navigate(`/notifications/${response?.payload?.id}?${queryString}`, { replace: true });
                }
                handleGetFields();
                setIsLoadingOnSubmit(false);
            })
            .catch((error) => {
                console.warn(error);
                toast.warning("Greška");
                setIsLoadingOnSubmit(false);
            });
    };

    useEffect(() => {
        handleGetData();
        handleGetFields();
    }, [notificationId]);

    const isSent = data?.status === "Poslato";

    const handleChangeNotificationStatus = () => {
        const queryString = setUrlQueryStringParam("tab", "status");
        navigate(`/notifications/${notificationId}?${queryString}`);
    };

    useEffect(() => {
        if (isSent) {
            handleChangeNotificationStatus();
        }
    }, [isSent]);

    const fieldsConfig = {
        status: {
            icon: IconList.inventory,
            component: <Status notificationId={notificationId} />,
        },
        content: {
            icon: IconList.inventory,
            component: <Content isSent={isSent} refetch={handleGetFields} formFields={formFieldsTemp} initialData={data} onSubmit={handleSaveData} loading={isLoadingOnSubmit} />,
        },
        portal_customers: { icon: IconList.group, component: <PortalCustomers notificationId={notificationId} /> },
        account: { icon: IconList.person, component: <Account isSent={isSent} notificationId={notificationId} /> },
        email: { icon: IconList.mail, component: <Email isSent={isSent} notificationId={notificationId} /> },
        overview: {
            icon: IconList.dashboard,
            component: (
                <Overview
                    onSubmit={() => {
                        handleChangeNotificationStatus();
                        handleGetData();
                        handleGetFields();
                    }}
                    notificationId={notificationId}
                />
            ),
        },
    };

    const accessibleFieldIds = new Set();
    const fieldNameMap = {};

    for (const { id, name } of accessibleFields) {
        accessibleFieldIds.add(id);
        fieldNameMap[id] = name;
    }

    const fields = Object.entries(fieldsConfig)
        .filter(([fieldId]) => accessibleFieldIds.has(fieldId))
        .map(([fieldId, { icon, component }]) => ({
            id: fieldId,
            name: fieldNameMap[fieldId] || fieldId,
            icon,
            enabled: true,
            component,
        }));

    const panelHandleSelect = (field) => {
        const id = data.id == null ? "new" : data.id;
        let queryString = setUrlQueryStringParam("tab", field.id);
        navigate(`/notifications/${id}?${queryString}`, { replace: true });
    };

    return (
        <DetailsPage
            title={data?.id == null ? "Novo obaveštenje" : data?.title}
            fields={fields}
            ready={[notificationId === "new" || data?.id]}
            selectedPanel={activeTab}
            panelHandleSelect={panelHandleSelect}
        />
    );
};

export default NotificationsDetails;
