import React from "react";
import ChipsField from "../../ChipsField/ui/ChipsField";
import { Stack, Typography } from "@mui/material";

export const ActiveFilters = ({ filters, onDelete }) => {
    return (
        <Stack gap={2} mb={2}>
            <Typography sx={{ fontSize: 18 }}>Izabrani filteri:</Typography>
            {filters.map((filter, index) => (
                <>{filter.activeFilters.length > 0 && <ChipsField data={filter} key={index} onDelete={onDelete} />}</>
            ))}
        </Stack>
    );
};
