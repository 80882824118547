import React, { useEffect, useState } from "react";
import ListPage from "../../../../components/shared/ListPage/ListPage";
import useAPI from "../../../../api/api";
import tableFields from "../forms/portalCustomersTableColumns.json";
import CustomFormDialog from "../../../../components/shared/Dialogs/CustomFormDialog";
import companyFilterFormFields from "../../../../components/Notifications/forms/notificationsDetailsCompanyFilterFields.json";
import scoringPackagesFilterFormFields from "../../../../components/Notifications/forms/notificationsDetailsScoringPackagesFilterFields.json";
import scoringFunctionsFilterFormFields from "../../../../components/Notifications/forms/notificationsDetailsScoringFunctionsFilterFields.json";
import contractFilterFormFields from "../../../../components/Notifications/forms/notificationsDetailsContractFilterFields.json";
import advisorsFilterFormFields from "../../../../components/Notifications/forms/notificationsDetailsAdvisorsFilterFields.json";
import { toast } from "react-toastify";
import CustomActionsDialog from "../../../../components/shared/Dialogs/CustomActionsDialog";

const PortalCustomers = ({ notificationId }) => {
    const api = useAPI();
    const [shownTableFields, setShownTableFields] = useState(tableFields);
    const [openDialog, setOpenDialog] = useState(null);
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [isClearFiltersModalOpen, setIsClearFiltersModalOpen] = useState(false);

    const handleOpenDialog = (dialogKey) => setOpenDialog(dialogKey);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleOpenClearFiltersModal = () => setIsClearFiltersModalOpen(true);
    const handleCloseClearFiltersModal = () => setIsClearFiltersModalOpen(false);

    const handleGetActiveFilters = () =>
        api.get(`/admin/notifications/portal-customers/filters/conditions/all/${notificationId}`, "NOTIFICATIONS_DETAILS").then((response) => setActiveFilters(response?.payload));

    useEffect(() => {
        handleGetActiveFilters();
    }, []);

    const customActions = {
        edit: {
            display: false,
        },
        delete: {
            clickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    return {
                        show: true,
                        id: rowData.id_portal_customer,
                        mutate: null,
                    };
                },
            },
            deleteClickHandler: {
                type: "dialog_delete",
                fnc: (rowData) => {
                    api.delete(`admin/notifications/portal-customers/list/${rowData.id_portal_customer}`, {}, "NOTIFICATIONS_DELETE")
                        .then(() => toast.success("Korisnik je uspešno obrisan"))
                        .catch((err) => toast.warning(err?.response?.data?.message ?? err?.response?.data?.payload?.message ?? "Došlo je do greške prilikom brisanja"));

                    return {
                        show: false,
                        id: rowData.id,
                        mutate: 1,
                    };
                },
            },
        },
    };

    const filterConfigs = [
        { name: "Firme", key: "portal_customers", formFields: companyFilterFormFields, apiPath: "portal-customers" },
        { name: "Scoring paketi", key: "packages_id", formFields: scoringPackagesFilterFormFields, apiPath: "scoring-packages" },
        { name: "Scoring funkcije", key: "packages_items_id", formFields: scoringFunctionsFilterFormFields, apiPath: "scoring-packages-items" },
        { name: "Ugovor - datumi", key: "period_id", formFields: contractFilterFormFields, apiPath: "portal-contracts/periods" },
        { name: "Savetnici", key: "advisors_id", formFields: advisorsFilterFormFields, apiPath: "portal-advisors" },
    ];

    const filterInputs = filterConfigs.map((filter) => {
        const activeFilterData = activeFilters
            .filter((activeFilter) => activeFilter.key === filter.key)
            .map((activeFilter) => ({
                id: activeFilter.id,
                value: activeFilter.value_data.name,
            }));

        return {
            name: filter.name,
            key: filter.key,
            activeFilters: activeFilterData,
        };
    });

    const filterActions = filterConfigs.map(({ name, key, formFields, apiPath }) => ({
        name,
        dialog: (
            <CustomFormDialog
                key={key}
                title={name}
                initialData={{ id_notifications: notificationId, [key]: [] }}
                formFields={formFields}
                isOpen={openDialog === key}
                onClose={handleCloseDialog}
                queryString={{ notification_id: notificationId }}
                onSave={handleGetActiveFilters}
                addFilterApiUrl={`/admin/notifications/portal-customers/filters/${apiPath}`}
                screen_code="NOTIFICATIONS_DETAILS"
            />
        ),
    }));

    const additionalButtons = [
        {
            label: "Poništi pretragu i filtere",
            clickHandler: {
                type: "",
                fnc: handleOpenClearFiltersModal,
            },
        },
        {
            label: "Pretraži",
            variant: "contained",
            clickHandler: {
                type: "",
                fnc: () =>
                    api
                        .post(
                            `admin/notifications/portal-customers/filters/conditions/filter`,
                            {
                                id_notifications: notificationId,
                            },
                            "NOTIFICATIONS_UPDATE"
                        )
                        .then(() => {
                            setDoesRefetch(true);
                        }),
            },
        },
    ];

    const handleDeleteChipFilter = (filterId, key) => {
        api.delete(`/admin/notifications/portal-customers/filters/conditions/${filterId}`, {}, "NOTIFICATIONS_DELETE").then(() => {
            setActiveFilters((prev) => prev.filter((filter) => !(filter.key === key && filter.id === filterId)));
        });
    };

    const handleConfirmClearFilters = () =>
        api
            .post(
                `admin/notifications/portal-customers/filters/conditions/reset`,
                {
                    id_notifications: notificationId,
                },
                "NOTIFICATIONS_UPDATE"
            )
            .then((response) => {
                toast.success(response.payload?.message);
                setActiveFilters([]);
                setDoesRefetch(true);
                handleCloseClearFiltersModal();
            });

    return (
        <>
            <CustomActionsDialog
                isOpen={isClearFiltersModalOpen}
                onConfirm={handleConfirmClearFilters}
                onClose={handleCloseClearFiltersModal}
                title={`Da li ste sigurni da želite da poništite pretragu i filtere?`}
            />
            <ListPage
                listPageId="PortalCustomers"
                apiUrl={`/admin/notifications/portal-customers/list/list/${notificationId}`}
                title="Portal korisnici"
                filterActions={filterActions}
                filterInputs={activeFilters.length > 0 ? filterInputs : []}
                onFilterChipDelete={handleDeleteChipFilter}
                columnFields={shownTableFields}
                customActions={customActions}
                showNewButton={false}
                additionalButtons={additionalButtons}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
                onOpenFilterDialog={handleOpenDialog}
                screen_code="NOTIFICATIONS_DETAILS"
            />
        </>
    );
};

export default PortalCustomers;
