import ListPage from "../../components/shared/ListPage/ListPage";
import listCheckbox from "./listCheckbox.json";

import tblFields from "./tblFields.json";
import useAPI from "../../api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import formFieldsPersonal from "../PortalCustomers/formFieldsPersonal.json";
import formFieldsBusiness from "../PortalCustomers/formFieldsBusiness.json";
import { toast } from "react-toastify";
import ModalForm from "../../components/shared/Modal/ModalForm";
import DeleteModal from "../../components/shared/Dialogs/DeleteDialog";
import { useMutation, useQuery } from "react-query";
import dataFields from "./dataCheckbox.json";
import DeleteDialog from "../../components/shared/Dialogs/DeleteDialog";

const Contracts = () => {
    const [doesRefetch, setDoesRefetch] = useState(false);
    const [stateRemove, setStateRemove] = useState([]);
    const [openModalUncheckedSet, setOpenModalUncheckedSet] = useState({ show: false, type: "" });
    let screen_code = "USERS";
    const api = useAPI();
    const [tableFields, setTableFields] = useState(tblFields);
    const { customerId, contractId } = useParams();

    const [openModal, setOpenModal] = useState({
        show: false,
        id: null,
        name: null,
        data: [],
        type: "",
    });

    const navigate = useNavigate();
    const [name, setName] = useState();
    const [formFields, setFormFields] = useState(dataFields);
    ////
    const customActions = {
        contracts: {
            title: "Nalozi",
            label: "Nalozi",
            icon: "group",
            position: 1,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${customerId}/${rowData?.id}?tab=accounts`;
                },
            },
        },
        edit: {
            icon: "edit",
            position: 2,
            clickHandler: {
                type: "navigate",
                fnc: (rowData) => {
                    return `/portal-customers/contracts/${customerId}/${rowData?.id}`;
                },
            },
        },
        clone: {
            title: "Dupliraj",
            type: "custom",
            label: "Dupliraj",
            icon: "file_copy",
            position: 3,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    setOpenModal({ show: true, id: rowData.id, name: rowData.name, type: "clone" });
                },
            },
        },
        delete: {
            position: 4,
            clickHandler: {
                type: "",
                fnc: (rowData) => {
                    setOpenModalUncheckedSet({ show: true, type: "delete" });
                    api.get(`/admin/contracts/list/message/${rowData?.id}`, "CONTRACTS_DELETE").then((res) => {
                        setOpenModal({ data: res?.payload, show: true, id: rowData.id, name: rowData.name, type: "delete" });
                    });
                },
            },
        },
    };

    // const getName = useCallback(async () => {
    //     return await api.post(`/admin/contracts/list/list`, { client_id: customerId }, screen_code).then((res) => {
    //         setName(res?.payload?.items[0]?.name);
    //     });
    // }, []);
    //
    // useEffect(() => {
    //     getName();
    // }, []);

    const deleteHandler = async (id) => {
        api.delete(`admin/contracts/list/confirm/${id}`, {}, "CONTRACTS_DELETE")
            .then((response) => {
                setOpenModal({ show: false, id: null, name: null, type: "" });
                setDoesRefetch(true);

                toast.success("Uspešno");
            })
            .catch((error) => {
                setOpenModal({ show: false, id: null, name: null, type: "" });
                toast.warn(error?.response?.data?.payload?.message ?? error?.response?.data?.message ?? "Greška");
                console.warn(error);
            });
    };

    // const confirmHandler = async (id) => {
    //     api.post(
    //         `admin/contracts/basic-data/clone`,
    //         {
    //             client_id: customerId,
    //             contracts_id: id,
    //         },
    //         screen_code
    //     )
    //         .then((response) => {
    //             setDoesRefetch(true);
    //             toast.success("Uspešno");
    //         })
    //         .catch((error) => {
    //             toast.warn(error?.response?.data?.payload?.message ?? error?.response?.data?.message ?? "Greška");
    //
    //             console.warn(error);
    //         });
    // };

    const { data: clientInfo } = useQuery(
        ["contracts", customerId],
        async () => {
            return await api.get(`admin/contracts/basic-data/data/${customerId}`, "CONTRACTS_DETAILS").then((res) => res?.payload);
        },
        { refetchOnWindowFocus: false }
    );

    const handleCloneMessage = (id) => {
        return useQuery(
            ["cloneMessage", id],
            async () => {
                return await api.get(`/admin/contracts/basic-data/clone/${id}`, "CONTRACTS_CLONE").then((res) => res?.payload);
            },
            {
                enabled: Boolean(openModal?.id),
            }
        );
    };

    const { data: cloneMessage, refetch: refetch_message } = handleCloneMessage(openModal?.id);

    const handleCloneOptions = () => {
        return useQuery(["cloneOptions", openModal?.id], async () => {
            return await api.get(`/admin/contracts/basic-data/ddl/clone-options`, "CONTRACTS_CLONE").then((res) => res?.payload);
        });
    };

    // const { data: cloneOptions, refetch: refetch_clone_options } = handleCloneOptions();

    useEffect(() => {
        if (openModal?.id && openModal?.id !== undefined && openModal?.id !== null) {
            refetch_message();
            // refetch_clone_options();
        }
    }, [openModal?.id]);

    const mutate_options = useMutation(["check_options", openModal?.id], async ({ data }) => {
        return await api.post(`admin/contracts/basic-data/ddl/clone-disabled-options`, data, "CONTRACTS_CLONE").then((res) => {
            let temp = formFields?.map((item) => {
                //
                if (res?.payload?.disabled?.includes(item.prop_name) || item.prop_name === "basic_data") {
                    return {
                        ...item,
                        disabled: true,
                    };
                } else {
                    return {
                        ...item,
                        disabled: false,
                    };
                }

                return item;
            });
            setFormFields(temp);
            return res?.payload;
        });
    });

    const validateData = (data, field) => {
        let ret = data;
        if (field === "renewal" || field === "start_from_today") {
            let renewal_val = Number(data["renewal"]);
            let start_from_today_val = Number(data["start_from_today"]);
            mutate_options.mutate({
                data: {
                    renewal: data["renewal"],
                    start_from_today: data["start_from_today"],
                },
            });
            if (renewal_val === 1 || start_from_today_val === 1) {
                ret["account_basic_data"] = 1;
                ret["account_scoring_packages"] = 1;
                ret["account_limits"] = 1;
                ret["scoring_packages"] = 1;
                ret["limits"] = 1;
                return ret;
            }
        }

        return ret;
    };

    useEffect(() => {
        mutate_options.mutate({
            data: {
                renewal: 1,
                start_from_today: 1,
            },
        });
    }, []);

    return (
        <>
            <ListPage
                listPageId="Contracts"
                apiUrl={`admin/contracts/list/list`}
                client_id={customerId}
                customActions={customActions}
                title={`Ugovor: ${clientInfo?.name}`}
                columnFields={tableFields}
                useColumnFields={true}
                showNewButton={true}
                screen_code={"CONTRACTS_LIST"}
                doesRefetch={doesRefetch}
                setDoesRefetch={setDoesRefetch}
            />
            {openModal?.type === "clone" && (
                <ModalForm
                    anchor="right"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    formFields={formFields}
                    sx={{ padding: "2rem" }}
                    apiPathFormModal="/admin/contracts/basic-data/ddl/clone-options"
                    apiPathFormModalWithoutId={`/admin/contracts/basic-data/clone`}
                    withoutSetterFunction
                    initialData={{ contracts_id: openModal.id, client_id: customerId }}
                    cancelButton
                    useNavigation={{
                        enabled: true,
                        basePath: `/portal-customers/contracts/${customerId}/`,
                    }}
                    withoutId
                    validateData={validateData}
                    label="Dupliraj"
                    styleCheckbox={{ padding: "0 0.563rem 0 0.563rem" }}
                    customTitle={cloneMessage?.main_line}
                    screen_code={"CONTRACTS_CLONE"}
                    setDoesRefetch={setDoesRefetch}
                />
            )}
            {openModal?.type === "delete" && (
                <DeleteDialog
                    title={openModal?.data?.portal_customer_line ?? "Obaveštenje"}
                    screen_code={"CLIENTS_DELETE"}
                    handleConfirm={() => {
                        deleteHandler(openModal?.id);
                    }}
                    description={openModal?.data?.main_line ?? ""}
                    openDeleteDialog={openModal}
                    setOpenDeleteDialog={setOpenModal}
                    nameOfButton={openModal?.data?.portal_customer_checkbox ?? "Obriši"}
                />
            )}
        </>
    );
};

export default Contracts;
