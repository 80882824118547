import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const CustomActionsDialog = ({ isOpen, onConfirm, onClose, title, content }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    maxWidth: "30rem",
                    borderRadius: 2,
                },
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
            }}
        >
            <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant={`outlined`} onClick={onClose}>
                    Ne
                </Button>
                <Button variant={`contained`} onClick={onConfirm}>
                    Da
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomActionsDialog;
