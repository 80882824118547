import React, { useState, useEffect } from "react";
import tableFields from "../forms/emailTableColumns.json";
import ListPage from "../../../../components/shared/ListPage/ListPage";

const Email = ({ isSent, notificationId }) => {
    const [shownTableFields, setShownTableFields] = useState(tableFields);

    const customActions = {
        edit: {
            display: false,
        },
    };

    const newSentNotificationStatusColumns = [
        {
            field_name: "Vreme dostave obaveštenja",
            prop_name: "delivery_time",
            in_main_table: true,
            in_details: false,
            editable: true,
            disabled: false,
            required: true,
            description: "",
            ui_prop: "xyz",
            sortable: true,
            input_type: "date_time",
        },
    ];

    useEffect(() => {
        if (isSent) {
            setShownTableFields((prev) => [...prev, ...newSentNotificationStatusColumns]);
        }
    }, [isSent]);

    return (
        <ListPage
            listPageId="Email"
            apiUrl={`/admin/notifications/emails/list/list/${notificationId}`}
            deleteUrl={`/admin/notifications/emails/list`}
            title="E-mail"
            columnFields={shownTableFields}
            customActions={customActions}
            showNewButton={false}
            screen_code="NOTIFICATIONS_DETAILS"
        />
    );
};

export default Email;
