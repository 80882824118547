import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../store/auth-contex";

class ApiService {
    /** The currently logged-in user. */
    user: ?{ access_token: string };

    /** Prepare the service. */
    constructor(user) {
        this.user = user;
    }

    /**
     * Execute the API call
     *
     * @param {"LIST","GET"|"PUT"|"POST"|"DELETE"} method The HTTP method to use.
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @param {string} screen_code
     * @return {Promise<APIResponse>}
     */
    _execute(method, path, payload = null, screen_code) {
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: localStorage.getItem("api") + path.replace(/^\//, ""),
                headers: { Authorization: `Bearer ${this.user?.access_token}`, Screen_Code: screen_code },
                data: payload,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    }

    /**
     * Execute the get API call
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     *
     * @param {string} screen_code
     * @return {Promise<APIResponse>}
     */
    get(path, screen_code) {
        return this._execute("GET", path, null, screen_code);
    }

    /**
     * Execute the pet API call
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @param screen_code
     * @return {Promise<APIResponse>}
     */
    put(path, payload, screen_code) {
        return this._execute("PUT", path, payload, screen_code);
    }

    /**
     * Execute the post API call
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @param screen_code
     * @return {Promise<APIResponse>}
     */
    post(path, payload, screen_code) {
        return this._execute("POST", path, payload, screen_code);
    }

    /**
     * Execute the list API call
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     * @param {?{}} payload The payload to send in the request.
     *
     * @param screen_code
     * @return {Promise<APIResponse>}
     */
    list(path, payload, screen_code) {
        return this._execute("LIST", path, payload, screen_code);
    }

    /**
     * Execute the delete API call
     *
     * @param {string} path The path to the API, without the domain and API version suffix.
     *
     * @param screen_code
     * @return {Promise<APIResponse>}
     */
    delete(path, payload, screen_code) {
        return this._execute("DELETE", path, payload, screen_code);
    }

    /**
     * Allow to change user data
     *
     * @param {array} userData Structure recived from AuthContext.user.
     */
    userDataUpdate(userData) {
        return (this.user = userData);
    }
}

/**
 * Get the reference to the API service.
 */
const useAPI = () => {
    const { user } = useContext(AuthContext);
    return useState(new ApiService(user))[0];
};

export default useAPI;
